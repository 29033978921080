import get from 'lodash.get'
import { takeEvery, put, all, call } from 'redux-saga/effects'
import { getList, getListSuccess, getListError } from './reducer'
import queryList from './query'
import fetchData from '../../utils'

function* watchGetList({ payload }) {
  const resGetList = yield call(fetchData, 'getList', queryList, payload)
  const errors = get(resGetList, 'errors', null)
  if (errors) {
    return yield put(getListError(get(errors, '[0].message', null)))
  }
  const data = get(resGetList, 'data', null)
  if (data) {
    yield put(getListSuccess(get(data, 'getCategoryList', [])))
  }
}

export const rootSagas = function* rootSagas() {
  yield all([takeEvery(getList.type, watchGetList)])
}
