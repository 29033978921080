const sagas = []
const reducers = {}
const moduleReducer = require.context('.', true, /reducer.js$/)

moduleReducer.keys().forEach((file) => {
  if (file === './reducer.js') return

  const module = moduleReducer(file)

  if (module.reducer) {
    reducers[module.namespace] = module.reducer
  }

  if (module.saga) {
    sagas.push(module.saga)
  }
})

export { reducers, sagas }
