import get from 'lodash.get'
import { takeEvery, put, all, call, take, cancelled } from 'redux-saga/effects'
import {
  getMessages,
  getMessagesSuccess,
  sendMessage,
  sendMessageSuccess,
  sendMessageError,
  loadMoreMessage,
  loadMoreMessageSuccess
} from './reducer'
import { firebaseApp } from '../../connections/firebase'
import { eventChannel } from '@redux-saga/core'

const db = firebaseApp.firestore()

function subscribeToExperiences(roomId) {
  return eventChannel((emitter) => {
    db.collection('message')
      .doc(roomId)
      .collection('messages')
      .orderBy('sentAt')
      .limitToLast(10)
      .onSnapshot({ includeMetadataChanges: true }, snapshot => {
        const experiences = snapshot.docChanges().filter(change => change.type === 'added' || change.type === 'modified').map(change => change.doc.data());

        if (snapshot.docChanges().length !== 0) {
          emitter(experiences);
        }
      })

    return () => db;
  });
}

function* watchGetMessages({ payload: { roomId } }) {
  let messages = []
  const channel = yield call(() => subscribeToExperiences(roomId));
  try {
    while (true) {
      const experiences = yield take(channel);
      messages = [...messages, ...experiences]
      yield put(getMessagesSuccess(messages))
    }
  }
  finally {
    if (yield cancelled()) {
      channel.close();
    }
  }
}

function* watchSendMessage({ payload: { messageText, firebaseUid, name, image, roomId } }) {
  if (messageText.trim()) {
    const message = {
      image,
      name,
      messageText,
      sentAt: new Date(),
      sentBy: firebaseUid,
    }
    const response = yield new Promise((resolve, reject) => {
      db.collection('message')
        .doc(roomId)
        .collection('messages')
        .add(message)
        .then(function () {
          resolve({ success: true, message })
        })
        .catch(function (error) {
          reject({ success: false, error })
        })
    })
    yield db.collection('group').doc(roomId).update({
      recentMessage: messageText
    })
    if (!response.success) {
      const errorMsg = get(response.error, '[0].message', null)
      yield put(sendMessageError(errorMsg))
    }
    yield put(sendMessageSuccess(get(message, 'messages', {})))
  }
}

function* watchLoadMoreMessage({ payload: { roomId, data } }) {
  const previousMessages = data?.[0] ? yield new Promise((resolve) => db.collection('message')
    .doc(roomId)
    .collection('messages')
    .where('sentAt', '<=', data[0].sentAt)
    .orderBy('sentAt')
    .limitToLast(10)
    .get()
    .then((querySnapshot) => {
      resolve(querySnapshot.docs.map(doc => doc.data()))
    })) : []
  let newMessages
  if (previousMessages.length === 1 && data?.[0].sentAt.seconds === previousMessages[0].sentAt.seconds && data?.[0].sentAt.nanoseconds === previousMessages[0].sentAt.nanoseconds) {
    newMessages = data
  } else {
    newMessages = previousMessages.concat(data)
  }
  yield put(loadMoreMessageSuccess(newMessages))
}

export const rootSagas = function* rootSagas() {
  yield all([
    takeEvery(getMessages.type, watchGetMessages),
    takeEvery(sendMessage.type, watchSendMessage),
    takeEvery(loadMoreMessage.type, watchLoadMoreMessage)
  ])
}
