import { createSlice } from '@reduxjs/toolkit'

export const namespace = 'auth'

const initialState = {
  loading: true,
  error: null,
  user: null,
  syncingFirebase: true
}

export const slice = createSlice({
  name: namespace,
  initialState,
  reducers: {
    login: (state) => ({
      ...state
      // loading: true,
    }),
    loginSuccess: (state, action) => ({
      ...state,
      loading: false,
      error: null,
      user: action.payload
    }),
    loginError: (state, action) => ({
      ...state,
      loading: false,
      error: action.payload,
      user: null
    }),
    logout: (state) => ({ ...state }),
    logoutSuccess: (state) => ({
      ...state,
      error: null,
      user: null
    }),
    forgotPassword: (state) => ({
      ...state
    }),
    resetPassword: (state) => ({
      ...state
    }),
    registerUser: (state) => ({
      ...state
    }),
    sendOTP: (state) => ({
      ...state
    }),
    verifyOTP: (state) => ({
      ...state
    }),
    syncingFirebaseSuccess: (state) => ({
      ...state,
      syncingFirebase: false
    })
  }
})

// Action creators are generated for each case reducer function
export const {
  login,
  loginSuccess,
  loginError,
  logout,
  logoutSuccess,
  forgotPassword,
  resetPassword,
  registerUser,
  sendOTP,
  verifyOTP,
  syncingFirebaseSuccess
} = slice.actions
// Selector
export const authSelector = (state) => state[namespace]
// Reducer
export const { reducer } = slice

export { rootSagas as saga } from './saga'
