import get from 'lodash.get'
import { takeEvery, put, all, call, fork, take } from 'redux-saga/effects'
import {
  getTransferRate,
  getTransferFee,
  getTransferRateSuccess,
  getTransferFeeSuccess
} from './reducer'
import {
  getTransferRateQuery,
  getTransferFeeQuery,
} from './query'
import fetchData from '../../utils'

function* watchGetTransferRate({ payload: { payload } }) {
  const res = yield call(fetchData, 'getTransferRate', getTransferRateQuery, payload, false)
  const errors = get(res, 'errors', null)
  if (errors) {
    const errorMsg = get(errors, '[0].message', null)
    return
  }
  const data = get(res, 'data.getTransferRate', null)
  if (data) {
    yield put(getTransferRateSuccess(data))
  }
}

function* watchGetTransferFee({ payload: { payload, callback } }) {
  const res = yield call(fetchData, 'getTransferFee', getTransferFeeQuery, payload, false)
  const errors = get(res, 'errors', null)
  if (errors) {
    const errorMsg = get(errors, '[0].message', null)
    return
  }
  const data = get(res, 'data.getTransferFee', null)
  if (data) {
    yield put(getTransferFeeSuccess(data.map(item => item.fee)))
  }
}

function* initData() {
  yield put(getTransferRate({
    payload: {
      from: "AUD",
      to: "VND"
    }
  }))
}

export const rootSagas = function* rootSagas() {
  yield all([
    fork(initData),
    takeEvery(getTransferRate.type, watchGetTransferRate),
    takeEvery(getTransferFee.type, watchGetTransferFee),
  ])
}
