import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'
// import { Auth0Provider } from '@auth0/auth0-react'
// import { oauthConfig } from './utils/config'

ReactDOM.render(
  // <Auth0Provider
  //   domain={oauthConfig.domain}
  //   clientId={oauthConfig.clientId}
  //   redirectUri={window.location.origin}
  //   audience={`https://${oauthConfig.domain}/api/v2/`}
  //   scope='read:current_user update:current_user_metadata'
  // >
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  // </Auth0Provider>,
  document.getElementById('root')
)

serviceWorker.unregister()
