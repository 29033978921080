import firebase from 'firebase'

export const firebaseConfig = {
  apiKey: "AIzaSyA6Xo4V_VBhYiU3NXRCHbPU9SarXBqT_no",
  authDomain: "cabramarket-7019b.firebaseapp.com",
  databaseURL: "https://cabramarket-7019b-default-rtdb.firebaseio.com",
  projectId: "cabramarket-7019b",
  storageBucket: "cabramarket-7019b.appspot.com",
  messagingSenderId: "828322366888",
  appId: "1:828322366888:web:597928c0127dd1022f499d",
  measurementId: "G-ZFCTCT2JHL"
};

export const firebaseApp = firebase.initializeApp(firebaseConfig);
