import get from 'lodash.get'
import { takeEvery, put, all, call } from 'redux-saga/effects'
import { get as getOne, getSuccess, getError, createOrder, updateOrder } from './reducer'
import { getOrder, createOrderRedux, updateOrderRedux } from './query'
import fetchData from '../../utils'

function* watchGet({ payload: { payload, callback } }) {
  const resGet = yield call(fetchData, 'get', getOrder, payload)
  const errors = get(resGet, 'errors', null)
  if (callback) {
    if (errors) {
      yield put(getError(get(errors, '[0].message', null)))
      return callback(getError(get(errors, '[0].message', null)))
    }
    const data = get(resGet, 'data', null)
    if (data) {
      yield put(getSuccess(get(data, 'getOrder', [])))
      return callback(getSuccess(get(data, 'getOrder', null)))
    }
  }
}

function* watchCreateOrder({ payload: { payload, callback } }) {
  const resGet = yield call(fetchData, 'createOrder', createOrderRedux, payload)
  const errors = get(resGet, 'errors', null)

  if (callback) {
    if (errors) {
      return callback(getError(get(errors, '[0].message', null)))
    }
    const data = get(resGet, 'data', null)
    if (data) {
      return callback(getSuccess(get(data, 'createOrder', null)))
    }
  }
}

function* watchUpdateOrder({ payload: { payload, callback } }) {
  const resGet = yield call(fetchData, 'updateOrder', updateOrderRedux, payload)
  const errors = get(resGet, 'errors', null)

  if (callback) {
    if (errors) {
      return callback(getError(get(errors, '[0].message', null)))
    }
    const data = get(resGet, 'data', null)
    if (data) {
      return callback(getSuccess(get(data, 'updateOrder', null)))
    }
  }
}

export const rootSagas = function* rootSagas() {
  yield all([
    takeEvery(getOne.type, watchGet),
    takeEvery(createOrder.type, watchCreateOrder),
    takeEvery(updateOrder.type, watchUpdateOrder)
  ])
}
