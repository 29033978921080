import get from 'lodash.get'
import { takeEvery, all, call } from 'redux-saga/effects'
import fetchData from '../../utils'
import { getPaymentIntent } from './reducer'
import { getPaymentIntentQuery } from './query'
function* watchGetPaymentIntent({ payload: { payload, callback } }) {
  const res = yield call(fetchData, 'getPaymentIntent', getPaymentIntentQuery, payload)
  const errors = get(res, 'errors', null)
  if (errors) {
    const errorMsg = get(errors, '[0].message', null)
    return callback && callback({ error: errorMsg })
  }
  const data = get(res, 'data.getPaymentIntent', null)
  if (data) {
    return callback && callback({data})
  }
}

export const rootSagas = function* rootSagas() {
  yield all([
    takeEvery(getPaymentIntent.type, watchGetPaymentIntent)
  ])
}
