import { createSlice } from '@reduxjs/toolkit'

export const namespace = 'transferMoney'

const initialState = {
  rate: 0,
  fee: [0, 0]
}

export const slice = createSlice({
  name: namespace,
  initialState,
  reducers: {
    getTransferRate: (state) => ({
      ...state
    }),
    getTransferFee: (state) => ({
      ...state
    }),
    getTransferRateSuccess: (state, action) => ({
      ...state,
      rate: action.payload
    }),
    getTransferFeeSuccess: (state, action) => ({
      ...state,
      fee: action.payload
    })
  }
})

// Action creators are generated for each case reducer function
export const {
  getTransferRate,
  getTransferFee,
  getTransferRateSuccess,
  getTransferFeeSuccess
} = slice.actions
// Selector
export const transferMoneySelector = (state) => state[namespace]
// Reducer
export const { reducer } = slice

export { rootSagas as saga } from './saga'
