export const loginRedux = `mutation login($email: String, $password: String!, $phone: String, $nation_phone_code: NationPhoneCodeType){
  login(email: $email, password: $password, phone: $phone, nation_phone_code: $nation_phone_code) {
    user {
      id
      username
      email
      full_name
      country
      address
      image
      type
      created_at
      updated_at
      user_phone {
        nation_phone_code
        phone
      }
    }
    token
    firebase_token
  }
}`

export const loginWithToken = `mutation loginWithToken($token: String!){
  loginWithToken(token: $token, ) {
    user {
      id
      username
      email
      full_name
      country
      address
      image
      type
      created_at
      updated_at
      user_phone {
        nation_phone_code
        phone
      }
    }
    token
    firebase_token
  }
}`

export const logout = `query logout {
  logout {
    status
  }
}`

export const forgotPasswordRedux = `mutation forgotPassword($email: String!){
  forgotPassword(email: $email, ) {
    status
  }
}`

export const resetPasswordRedux = `mutation resetPassword($email: String!, $forgotPasswordToken: String!, $password: String!){
  resetPassword(email: $email, forgotPasswordToken: $forgotPasswordToken, password: $password) {
    status
  }
}`

export const registerUserRedux = `
  mutation registerUser(
    $email: String!
    $full_name: String!
    $username: String!
    $nation_phone_code: NationPhoneCodeType!
    $phone: String!
    $password: String!
    $confirm_password: String!
    $country: CountryType!
    $address: String!
    $code: String!
  ) {
    registerUser(
      email: $email
      full_name: $full_name
      username: $username
      nation_phone_code: $nation_phone_code
      phone: $phone
      password: $password
      confirm_password: $confirm_password
      country: $country
      address: $address
      code: $code
      ) {
      full_name
    }
  }
`

export const sendRegisterOTPRedux = `
  mutation sendRegisterOTP ($nation_phone_code: NationPhoneCodeType!, $phone: String!) {
    sendRegisterOTP (nation_phone_code: $nation_phone_code, phone: $phone)
  }
`

export const verifyRegisterOTPRedux = `
  mutation verifyRegisterOTP ($nation_phone_code: NationPhoneCodeType!, $phone: String!, $code: String!) {
    verifyRegisterOTP (nation_phone_code: $nation_phone_code, phone: $phone, code: $code)
  }
`
