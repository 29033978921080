import get from 'lodash.get'
import { takeEvery, put, all, call } from 'redux-saga/effects'

import { getOrdersByUser, getSuccess, getError } from './reducer'
import { getOrdersByUser as getOrdersByUserQuery } from './query'
import fetchData from '../../utils'

function* watchGetOrdersByUser({ payload: { payload, callback } }) {
  const resGet = yield call(fetchData, 'getOrdersByUser', getOrdersByUserQuery, payload)
  const errors = get(resGet, 'errors', null)
  if (callback) {
    if (errors) {
      yield put(getError(get(errors, '[0].message', null)))
      return callback(errors)
    }
    const data = get(resGet, 'data', null)
    if (data) {
      yield put(getSuccess(get(data, 'getOrderListByUserId', [])))
      return callback(data)
    }
  }
}

export const rootSagas = function* rootSagas() {
  yield all([takeEvery(getOrdersByUser.type, watchGetOrdersByUser)])
}
