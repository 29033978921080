import get from 'lodash.get'
import { takeEvery, put, all, call } from 'redux-saga/effects'
import { getProvince, getSuccess, getError } from './reducer'
import query from './query'
import fetchData from '../../utils'

function* watchGet({ payload }) {
  const resGet = yield call(fetchData, 'getProvince', query, payload)
  const errors = get(resGet, 'errors', null)

  // if (callback) {
  if (errors) {
    yield put(getError(get(errors, '[0].message', null)))
    // return callback(getError(get(errors, '[0].message', null)))
  }
  const data = get(resGet, 'data', null)
  if (data) {
    yield put(getSuccess(get(data, 'getProvince', [])))
    // return callback(getSuccess(get(data, 'getProvince', null)))
  }
  // }
}

export const rootSagas = function* rootSagas() {
  yield all([takeEvery(getProvince.type, watchGet)])
}
