export default `query getProvince($id: ID!){
  getProvince(id: $id){	
    id
    ghn_id
    description {
      name
    }
    districts {
      id
      ghn_id
      description {
        name
      }
      wards {
        ghn_id
        description {
          name
        }
      }
    }
  }
}`
