import { createSlice } from '@reduxjs/toolkit'
export const namespace = 'room-chat'

const initialState = {
  loading: true,
  error: null,
  data: []
}

export const slice = createSlice({
  name: namespace,
  initialState,
  reducers: {
    getRoomChat: (state) => ({
      ...state,
      loading: true
    }),
    getRoomChatSuccess: (state, action) => ({
      ...state,
      error: null,
      data: action.payload,
      loading: false
    }),
    getRoomChatError: (state, action) => ({
      ...state,
      loading: false,
      error: action.payload,
      data: []
    }),
    createRoomChat: (state) => ({
      ...state
    }),
    createRoomChatSuccess: (state, action) => ({
      ...state,
      error: null,
      data: action.payload,
      loading: false
    }),
    createRoomChatError: (state, action) => ({
      ...state,
      loading: false,
      error: action.payload
    })
  }
})

// Action creators are generated for each case reducer function
export const {
  getRoomChat,
  getRoomChatSuccess,
  getRoomChatError,
  createRoomChat,
  createRoomChatSuccess,
  createRoomChatError
} = slice.actions
// Selector
export const roomChatSelector = (state) => state[namespace]
// Reducer
export const { reducer } = slice

export { rootSagas as saga } from './saga'
