export const getOrderByCode = `
query getLookUpOrders($order_code: String!){
  getLookUpOrders(order_code: $order_code){
    order_code
    order_info
    order_recipient
    order_send
    tracking_logs
  }
}
`
