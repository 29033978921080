import { createSlice } from '@reduxjs/toolkit'
export const namespace = 'room-chat-message'

const initialState = {
  loading: true,
  error: null,
  data: []
}

export const slice = createSlice({
  name: namespace,
  initialState,
  reducers: {
    getMessages: (state) => ({
      ...state
    }),
    getMessagesSuccess: (state, action) => ({
      ...state,
      error: null,
      data: action.payload,
      loading: false
    }),
    getMessagesError: (state, action) => ({
      ...state,
      loading: false,
      error: action.payload,
      data: []
    }),
    sendMessage: (state) => ({
      ...state
    }),
    sendMessageSuccess: (state, action) => ({
      ...state,
      error: null,
      loading: false
    }),
    sendMessageError: (state, action) => ({
      ...state,
      loading: false,
      error: action.payload,
      data: null
    }),
    loadMoreMessage: (state) => ({
      ...state
    }),
    loadMoreMessageSuccess: (state, action) => ({
      ...state,
      error: null,
      data: action.payload,
      loading: false
    }),
    loadMoreMessageError: (state, action) => ({
      ...state,
      loading: false,
      error: action.payload,
      data: []
    })
  }
})

// Action creators are generated for each case reducer function
export const {
  getMessages,
  getMessagesSuccess,
  getMessagesError,
  sendMessage,
  sendMessageSuccess,
  sendMessageError,
  loadMoreMessage,
  loadMoreMessageSuccess,
  loadMoreMessageError
} = slice.actions
// Selector
export const chatSelector = (state) => state[namespace]
// Reducer
export const { reducer } = slice

export { rootSagas as saga } from './saga'
