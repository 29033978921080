import get from 'lodash.get'
import { takeEvery, put, all, call } from 'redux-saga/effects'
import { getFee, getSuccess, getError } from './reducer'
import { getFeeQuery } from './query'
import fetchData from '../../utils/'

function* watchGetFee({ payload: { payload, callback } }) {
  const resGet = yield call(fetchData, 'getFee', getFeeQuery, payload)
  const errors = get(resGet, 'errors', null)

  if (callback) {
    if (errors) {
      yield put(getError(get(errors, '[0].message', null)))
      return callback(getError(get(errors, '[0].message', null)))
    }
    const data = get(resGet, 'data', null)
    if (data) {
      yield put(getSuccess(get(data, 'getFee', [])))
      return callback(getSuccess(get(data, 'getFee', null)))
    }
  }
}

export const rootSagas = function* rootSagas() {
  yield all([takeEvery(getFee.type, watchGetFee)])
}
