export const getOrdersByUser = `query getOrderListByUserId($user_id: Int, $keyword: String, $status: StatusType, $limit: Int, $offset: Int, $language_code: String){
  getOrderListByUserId(user_id: $user_id, keyword: $keyword, status: $status, limit: $limit, offset: $offset, language_code: $language_code) {
    data {
      id
      user_id
      code
      order_status {
        id
        name
      }
      carrier {
        id
        name
        carrier_order_status {
          id
          name
        }
      }
      code_carrier
      location
      payment_type {
        id
        name
      }
      service_type_id
      price
      order_status_logs {
        id
        log
      }
      order_products {
        id
        category {
          description {
            title
          }
        }
        product_name
        amount
        price
        weight
      }
      order_recipient {
        name
        phone
        email
        province {
          id
          ghn_id
          description {
            name
          }
        }
        district {
          id
          ghn_id
          description {
            name
          }
        }
        ward {
          id
          ghn_id
          description {
            name
          }
        }
        country
        address
      }
      order_send {
        name
        phone
        email

        country
        address
      }
      warehouse_sydney_order
      warehouse_sydney_tracking
      warehouse_sydney
      currency
      fee
      surcharge
      pickup_content
      pickup_date
      pickup
      note
      number_customer
      width
      length
      height
      weight
      bales
      price
    }
    count
  }
}
`
