import get from 'lodash.get'
import {takeEvery, put, all, call, select, take, cancelled} from 'redux-saga/effects'
import {
  getRoomChat,
  getRoomChatSuccess,
  createRoomChat,
  createRoomChatError
} from './reducer'
import { firebaseApp } from '../../connections/firebase'
import { authSelector } from '../auth/reducer'
import { COOKIE_TOKEN_NAME } from '../../utils/constant'
import fetchData from '../../utils'
import { loginWithToken } from '../auth/query'
import Cookies from 'js-cookie'
import { eventChannel } from "@redux-saga/core";

const db = firebaseApp.firestore()

function subscribeToExperiences(firebaseUid) {
  return eventChannel((emitter) => {
    db.collection('group').where('members', 'array-contains', firebaseUid).onSnapshot((snapshot) => {
      const experiences = snapshot
        .docChanges()
        .filter(change => change.type === 'added')
        .map(change => ({
          ...change.doc.data(),
          id: change.doc.id
        }));
      emitter(experiences);
    })
    return () => db;
  })
}

function* watchGetRoomChat() {
  let { user } = yield select(authSelector)
  if (!user) {
    const token = Cookies.get(COOKIE_TOKEN_NAME)

    if (!token) return

    const res = yield call(fetchData, 'loginWithToken', loginWithToken, {
      token
    })
    const errors = get(res, 'errors', null)

    if (errors) {
      Cookies.remove(COOKIE_TOKEN_NAME)
      return (window.location.pathname = '/login')
    }
    const data = get(res, 'data.loginWithToken', null)
    let firebaseUid
    if (data.firebase_token) {
      try {
        const firebaseUser = yield firebaseApp.auth().signInWithCustomToken(data.firebase_token)
        firebaseUid = firebaseUser.user.uid
      } catch (_) {}
    }
    user = data.user
    user.firebaseUid = firebaseUid
  }
  const { firebaseUid } = user
  let rooms = []
  const channel = yield call(() => subscribeToExperiences(firebaseUid));
  try {
    while (true) {
      const experiences = yield take(channel);
      rooms = [...rooms, ...experiences]
      yield put(getRoomChatSuccess(rooms))
    }
  }
  finally {
    if (yield cancelled()) {
      channel.close();
    }
  }
}

function* watchCreateRoomChat({ payload: { receiver } }) {
  let { user } = yield select(authSelector)
  const { firebaseUid, full_name, image } = user

  try {
    yield db.collection('group')
      .add({
        createdBy: firebaseUid,
        image: {
          [firebaseUid]: image,
          [receiver.uid]: receiver.image || null
        },
        members: [firebaseUid, receiver.uid],
        name: {
          [firebaseUid]: full_name,
          [receiver.uid]: receiver.displayName
        }
      })
  } catch (errors) {
    yield put(createRoomChatError(errors))
  }
}

export const rootSagas = function* rootSagas() {
  yield all([
    takeEvery(getRoomChat.type, watchGetRoomChat),
    takeEvery(createRoomChat.type, watchCreateRoomChat)
  ])
}
