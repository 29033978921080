export const getTransferRateQuery = `query getTransferRate($from: String!, $to: String!){
  getTransferRate(from: $from, to: $to)
}`

export const getTransferFeeQuery = `query getTransferFee($from: String!, $to: String!, $toAmount: Int!){
  getTransferFee(from: $from, to: $to, toAmount: $toAmount) {
    type
    fee
  }
}`
