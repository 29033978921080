export const getFeeQuery = `query getFee($service_type_id: Int! $currency: CurrencyType $to_district_id: Int! $to_ward_code: String! $height: Int! $length: Int! $width: Int! $weight: Int! $insurance_value: Int! $coupon: String!) {
  getFee(
    service_type_id: $service_type_id,
    currency: $currency,
    to_district_id: $to_district_id,
    to_ward_code: $to_ward_code,
    height: $height,
    length: $length,
    width: $width,
    weight: $weight,
    insurance_value: $insurance_value,
    coupon: $coupon
  ) {
    fee
    surcharge
    service_type_id
  }
}`
